/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 50번째 로그에서는 The Cloud 100, 기기 간 동기화 앱 Syncthink, Notion 학생 무료 플랜 공개 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "The Cloud 100"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.forbes.com/cloud100/#5d6884b5f941"
  }, "The Cloud 100 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/"
  }, "HashiCorp: Infrastructure enables innovation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zapier.com/"
  }, "Zapier | The easiest way to automate your work")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Economy_of_California#/media/File:2017_GDP_comparison_by_country_or_US_state.png"
  }, "2017 GDP comparison by country or US state - Economy of California - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://opennet.or.kr/16456"
  }, "블랙넛 모욕죄 처벌, 예술작품에 대한 국가권력 개입을 우려한다 | Open Net")), "\n"), "\n", React.createElement(_components.h2, null, "if (kakao) dev 2019 영상 공개"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://if.kakao.com/2019/program"
  }, "if (kakao) dev 2019 프로그램")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/events/summits/seoul/"
  }, "AWS Summit Seoul")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/daangn"
  }, "당근마켓 팀블로그 – Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/user/kthcorp/playlists"
  }, "kthcorp H3 컨퍼런스 재생목록 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://if.kakao.com/2019/program?sessionId=eebbe5ae-0c77-4f52-83af-5818f9fd6c26"
  }, "카카오톡 적용 사례를 통해 살펴보는 카카오 클라우드의 Kubernetes as a Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://if.kakao.com/2019/program?sessionId=a005c64e-6afa-4810-92ca-43a014aa8aac"
  }, "자동화된 클라우드를 위하여 - if (kakao) dev 2019")), "\n"), "\n", React.createElement(_components.h2, null, "기기간 동기화 앱 Syncthing"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/syncthink-syncing-file-between-multiple-devices"
  }, "싱크띵(Syncthing), 여러 기기간 파일 동기화 애플리케이션: 드롭박스 대신 무료로 가능한 오픈소스 프로젝트 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://syncthing.net/"
  }, "Syncthing")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/faq/Sync/Subscribe%20to%20Bear%20Pro/"
  }, "Subscribe to Bear Pro - Bear - Faq and support")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.devontechnologies.com/blog/devonthink-30"
  }, "DEVONtechnologies | DEVONthink 3.0 is Officially Here")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.bear.app/2019/09/bear-1-7-is-here-with-note-encryption-bear-lock-live-note-links-and-more/"
  }, "Bear 1.7 is here with note Encryption, Bear Lock, Live Note Links, and more!")), "\n"), "\n", React.createElement(_components.h2, null, "Notion 학생 무료 플랜 제공"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/students"
  }, "Notion – Free for students")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/educators"
  }, "Notion – Free for educators")), "\n"), "\n", React.createElement(_components.h2, null, "구글 코드리뷰 가이드 라인"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://google.github.io/eng-practices/review/"
  }, "Code Review Developer Guide | eng-practices")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://soojin.ro/review/"
  }, "Review · Soojin Ro")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wiki.lucashan.space/code-review/01.intro.html"
  }, "Introduce for code review guide | Lucas's wiki")), "\n"), "\n", React.createElement(_components.h2, null, "FECONF 2019"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://2019.jsconfkorea.com/"
  }, "JSConf Korea 2019 | JSConf Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://2019.feconf.kr/"
  }, "FEConf 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://booking.naver.com/booking/5/bizes/263881/items/3170721?area=bbt"
  }, "네이버 예약 - FECONF")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/90"
  }, "FEConf 2018 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=F7fwB90JdXI"
  }, "FEconf 2019 Promotional Video - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/playlist?list=PLZl3coZhX98oeg76bUDTagfySnBJin3FE"
  }, "FEConf 2018 Korea - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/44bits"
  }, "44bits - YouTube")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
